import React from 'react';
import PropTypes from 'prop-types';
import labels from '../i18n';

const FactsComponent = (props) => {
  const { lang } = props;
  return (
    <div className="row facts">
      <div className="column large-4">
        &nbsp;
      </div>
      <div className="column large-8 medium-12 small-12">
        <p>{labels[lang].factsLarge}</p>
        <p>{labels[lang].factsSmall}</p>
        <h3>{labels[lang].factsHeadline}</h3>
        <ul className="resetlist">
          <li>
            <p>{labels[lang].factsStudents}</p>
            <p>{labels[lang].factsStudentsNumber}</p>
          </li>
          <li>
            <p>{labels[lang].factsStaff}</p>
            <p>{labels[lang].factsStaffNumber}</p>
          </li>
          <li>
            <p>{labels[lang].factsBudget}</p>
            <p>{labels[lang].factsBudgetNumber}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

FactsComponent.propTypes = {
  lang: PropTypes.string.isRequired,
};

FactsComponent.displayName = 'FactsComponent';
export default FactsComponent;
