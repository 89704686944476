/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const DepartmentsComponent = (props) => {
  const { departments } = props;
  const renderDepartments = departments.sort((a, b) => (
    (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))).map((d, i) => (
      <li key={i}>
        <a href={d.url}>{d.name}</a>
        {(() => {
          if (d.children.length > 0) {
            const renderChildren = d.children.map((c, j) => (
              <li key={j}><a href={c.url}>{c.name}</a></li>
            ));
            return (
              <ul>
                {renderChildren}
              </ul>
            );
          }
          return null;
        })()}
      </li>
  ));

  return (
    <ul>
      {renderDepartments}
    </ul>
  );
};
DepartmentsComponent.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DepartmentsComponent.displayName = 'DepartmentsComponent';
export default DepartmentsComponent;
