import { GET_BUILDINGS } from '@aarhus-university/au-lib-react-maps/src/actions/const';
import { GET_DEPARTMENTS } from '../actions/const';

const initialState = {
  Departments: [],
  Buildings: [],
};

export default (state = initialState, action) => {
  const nextState = { ...state };
  switch (action.type) {
    case GET_DEPARTMENTS: {
      const { data } = action.payload;
      if (data.groupings.length === 1) {
        nextState.Departments = data.groupings[0].associations;
      }
      return nextState;
    }

    case GET_BUILDINGS: {
      const { data } = action.payload;
      nextState.Buildings = data;
      return nextState;
    }

    default: {
      return state;
    }
  }
};
