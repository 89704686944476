import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DepartmentsComponent from './DepartmentsComponent';

class FacultiesComponent extends Component {
  componentDidMount() {
    const { faculties, getDepartments, lang } = this.props;
    if (faculties.length === 0) {
      getDepartments(lang === 'da' ? 45 : 51); // Webtools listeid
    }
  }

  static renderLink(faculty) {
    const links = ['http://arts.au.dk', 'http://health.au.dk', 'http://nat.au.dk', 'http://tech.au.dk', 'http://bss.au.dk'];
    return links[faculty];
  }

  render() {
    /* eslint-disable react/no-array-index-key */
    const { faculties } = this.props;
    const renderFaculties = faculties.map((f, i) => (
      <div key={i} className="column xlarge-4 large-6 medium-6 small-12 faculty">
        <h3><a href={FacultiesComponent.renderLink(i)}>{f.name}</a></h3>
        <DepartmentsComponent key={i} departments={f.items} />
      </div>
    ));

    return (
      <div className="row map-shadow">
        {renderFaculties}
      </div>
    );
  }
}

FacultiesComponent.propTypes = {
  faculties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDepartments: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

FacultiesComponent.displayName = 'FacultiesComponent';
export default FacultiesComponent;
