/* eslint-env browser */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SearchComponent from '@aarhus-university/au-lib-react-maps/src/components/SearchComponent';
import getBuildings from '@aarhus-university/au-lib-react-maps/src/actions/getBuildings';
import LinkComponent from '../components/LinkComponent';
import MapComponent from '../components/MapComponent';
import FacultiesComponent from '../components/FacultiesComponent';
import FactsComponent from '../components/FactsComponent';
import getDepartments from '../actions/getDepartments';
import labels from '../i18n';
import '../styles/styles.scss';

class AppComponent extends Component {
  constructor(props) {
    super(props);
    const { selected } = props;

    this.state = {
      selected,
    };

    this.setSelected = this.setSelected.bind(this);
  }

  setSelected(selected) {
    this.setState({ selected });
  }

  render() {
    const { selected } = this.state;
    const {
      actions,
      presentations,
      singleMode,
      lang,
    } = this.props;
    const renderSelected = () => {
      const s = selected;
      switch (s) {
        case 1: {
          return <FactsComponent lang={lang} />;
        }
        case 2: {
          return (
            <FacultiesComponent
              getDepartments={actions.getDepartments}
              faculties={presentations.Departments}
              lang={lang}
            />
          );
        }
        case 4: {
          return (
            <div className="au-map find-container">
              <div className="row">
                <div className="column large-6 medium-6 small-12">
                  <SearchComponent
                    lang={lang}
                    container="au_map_presentation"
                    width="100%"
                    height={200}
                    zoom={17}
                    center={{
                      lat: 56.168995,
                      lng: 10.203113,
                    }}
                    scrollable={false}
                    draggable={false}
                    clickable={false}
                    streetview={false}
                    className="row"
                    sideBySide={false}
                    fetchLocally
                    locations={presentations.Buildings}
                    getLocations={actions.getBuildings}
                  />
                </div>
              </div>
            </div>
          );
        }
        case 5: {
          return <MapComponent dkOnly={window.onlyDkMapPresentation || false} lang={lang} />;
        }
        default: {
          return <div />;
        }
      }
    };

    if (singleMode) {
      return (
        <div className="column large-12 medium-12 small-12 main">
          {renderSelected()}
        </div>
      );
    }

    return (
      <div className="column large-12 medium-12 small-12">
        <div className="row">
          <div className="column large-3 medium-4 small-12 left-presentation">
            <h3>Find</h3>
            <ul className="resetlist">
              <LinkComponent
                index={1}
                text={labels[lang].tab1}
                selected={selected}
                setSelected={this.setSelected}
              />
              <LinkComponent
                index={2}
                text={labels[lang].tab2}
                selected={selected}
                setSelected={this.setSelected}
              />
              <li>
                <a href={labels[lang].tab3_link}>
                  {labels[lang].tab3}
                </a>
              </li>
              <LinkComponent
                index={4}
                text={labels[lang].tab4}
                selected={selected}
                setSelected={this.setSelected}
              />
              <LinkComponent
                index={5}
                text={labels[lang].tab5}
                selected={selected}
                setSelected={this.setSelected}
              />
            </ul>
          </div>
          <div className="column large-9 medium-8 small-12 right-presentation main">
            {renderSelected()}
          </div>
        </div>
      </div>
    );
  }
}

AppComponent.propTypes = {
  presentations: PropTypes.shape({
    Departments: PropTypes.shape({}).isRequired,
    Buildings: PropTypes.shape({}).isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    getDepartments: PropTypes.func.isRequired,
    getBuildings: PropTypes.func.isRequired,
  }).isRequired,
  selected: PropTypes.number.isRequired,
  singleMode: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const props = {
    presentations: state.presentations,
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getDepartments,
    getBuildings,
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

AppComponent.displayName = 'AppComponent';
export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
