import axios from 'axios';
import { GET_DEPARTMENTS, API_HOST } from './const';

const API_URL = '/Databox/Json/';

export default (list) => {
  const url = `${API_HOST}${API_URL}?id=${list}`;
  const request = axios.get(url);

  return {
    type: GET_DEPARTMENTS,
    payload: request,
  };
};
