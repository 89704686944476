/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'pui-react-tooltip';
import { OverlayTrigger } from 'pui-react-overlay-trigger';
import labels from '../i18n';
// var ReactTooltip = require('react-tooltip');
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint jsx-a11y/control-has-associated-label: 0 */

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.renderDots = this.renderDots.bind(this);
  }

  renderDots(index) {
    const { lang } = this.props;
    const locationsDK = [
      {
        ID: 1,
        Name: 'Askov',
        ClassName: 'askov',
        Major: false,
        Text: labels[lang].askov,
        Link: labels[lang].askov_link,
      },
      {
        ID: 2,
        Name: 'Emdrup (København)',
        ClassName: 'emdrup',
        Major: true,
        Text: labels[lang].emdrup,
        Link: labels[lang].emdrup_link,
      },
      {
        ID: 3,
        Name: 'Flakkebjerg',
        ClassName: 'flakkebjerg',
        Major: false,
        Text: labels[lang].flakkebjerg,
        Link: labels[lang].flakkebjerg_link,
      },
      {
        ID: 4,
        Name: 'Foulum',
        ClassName: 'foulum',
        Major: false,
        Text: labels[lang].foulum,
        Link: labels[lang].foulum_link,
      },
      {
        ID: 5,
        Name: 'Herning',
        ClassName: 'herning',
        Major: true,
        Text: labels[lang].herning,
        Link: labels[lang].herning_link,
      },
      {
        ID: 6,
        Name: 'Horsens',
        ClassName: 'horsens',
        Major: false,
        Text: labels[lang].horsens,
        Link: labels[lang].horsens_link,
      }, /*
            {
                ID: 7,
                Name: 'Jels',
                ClassName: 'jels',
                Major: false,
                Text: labels[lang].jels
            }, */
      {
        ID: 8,
        Name: 'Jyndevad',
        ClassName: 'jyndevad',
        Major: false,
        Text: labels[lang].jyndevad,
        Link: labels[lang].jyndevad_link,
      },
      {
        ID: 9,
        Name: 'Kalø/Rønde',
        ClassName: 'kalo',
        Major: false,
        Text: labels[lang].kalo,
        Link: labels[lang].kalo_link,
      },
      {
        ID: 10,
        Name: 'Roskilde',
        ClassName: 'roskilde',
        Major: false,
        Text: labels[lang].roskilde,
        Link: labels[lang].roskilde_link,
      },
      {
        ID: 11,
        Name: 'Rønbjerg',
        ClassName: 'ronbjerg',
        Major: false,
        Text: labels[lang].ronbjerg,
        Link: labels[lang].ronbjerg_link,
      },
      {
        ID: 12,
        Name: 'Silkeborg',
        ClassName: 'silkeborg',
        Major: false,
        Text: labels[lang].silkeborg,
        Link: labels[lang].silkeborg_link,
      },
      {
        ID: 13,
        Name: 'Aarhus',
        ClassName: 'aarhus',
        Major: true,
        Text: labels[lang].aarhus,
        Link: labels[lang].aarhus_link,
      },
      {
        ID: 14,
        Name: 'Årslev',
        ClassName: 'aarslev',
        Major: false,
        Text: labels[lang].aarslev,
        Link: labels[lang].aarslev_link,
      },
      {
        ID: 15,
        Name: 'Moesgaard',
        ClassName: 'moesgaard',
        Major: false,
        Text: labels[lang].moesgaard,
        Link: labels[lang].moesgaard_link,
      },
      {
        ID: 16,
        Name: 'Grundtvig Centeret (København)',
        ClassName: 'grundtvig',
        Major: false,
        Text: labels[lang].grundtvig,
        Link: labels[lang].grundtvig_link,
      },
    ];

    const locationsGL = [
      /* {
          ID: 1,
          Name: 'Nuuk',
          ClassName: 'nuuk',
          Major: false,
          Text: labels[lang].nuuk
      }, */
      {
        ID: 2,
        Name: 'Villum Research Station',
        ClassName: 'villum',
        Major: false,
        Text: labels[lang].villum,
        Link: labels[lang].villum_link,
      },
      {
        ID: 3,
        Name: 'Zackenberg',
        ClassName: 'zackenberg',
        Major: false,
        Text: labels[lang].zackenberg,
        Link: labels[lang].zackenberg_link,
      },
    ];

    const locationsTF = [
      {
        ID: 1,
        Name: 'SONG-teleskopet',
        ClassName: 'song',
        Major: false,
        Text: labels[lang].song,
        Link: labels[lang].song_link,
      },
    ];

    const locations = [
      window.mapLocationsDK || locationsDK,
      window.mapLocationsGL || locationsGL,
      window.mapLocationsTF || locationsTF,
    ];

    const trigger = window.matchMedia('(max-width: 640px)').matches ? 'click' : 'hover';
    return locations[index].map((l) => (
      <li key={l.ID}>
        <OverlayTrigger placement="top" trigger={trigger} overlay={<Tooltip><div dangerouslySetInnerHTML={{ __html: l.Text }} /></Tooltip>}>
          <a
            href={l.Link}
            onClick={(e) => {
              if (!$('.pui-tooltip-inner').is(':visible')) {
                e.preventDefault();
              }
            }}
            className={l.Major ? `overlay-trigger large ${l.ClassName}` : `overlay-trigger ${l.ClassName}`}
          />
        </OverlayTrigger>
      </li>
    ));
  }

  render() {
    const { dkOnly, lang } = this.props;
    const largeDkClass = dkOnly ? 'large-12' : 'large-7';

    return (
      <div className="row">
        <div className={`column ${largeDkClass} medium-12 small-12 location-container`}>
          <div className="map-wrapper">
            <div className="map" />
            <ul className="resetlist overlay">
              {this.renderDots(0)}
            </ul>
          </div>
        </div>
        {
          !dkOnly && (
            <div className="column large-5 medium-12 small-12 location-container">
              <div dangerouslySetInnerHTML={
                { __html: labels[lang].presentation }
              }
              />
              <div className="row">
                <div className="column large-6 medium-6 small-12">
                  <div className="border">
                    <h5><strong>{labels[lang].greenland}</strong></h5>
                    <div className="map-wrapper gl">
                      <div className="map" />
                      <ul className="resetlist overlay">
                        {this.renderDots(1)}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="column large-6 medium-6 small-12">
                  <div className="border">
                    <h5><strong>Tenerife</strong></h5>
                    <div className="map-wrapper tf">
                      <div className="map" />
                      <ul className="resetlist overlay">
                        {this.renderDots(2)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

MapComponent.propTypes = {
  dkOnly: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};
MapComponent.displayName = 'MapComponent';
export default MapComponent;
