import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LinkComponent extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { setSelected, index } = this.props;
    e.preventDefault();
    setSelected(index);
  }

  render() {
    const { index, selected, text } = this.props;
    return (
      <li>
        <button type="button" className={selected === index ? 'selected' : ''} href="" onClick={this.handleClick}>{text}</button>
      </li>
    );
  }
}

LinkComponent.propTypes = {
  setSelected: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

LinkComponent.displayName = 'LinkComponent';
export default LinkComponent;
