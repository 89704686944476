/* eslint-env browser */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';

import AppComponent from './containers/AppComponent';
import reducers from './reducers';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);

render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <AppComponent
      selected={window.selectedPresentation || 1}
      singleMode={window.singlePresentation || false}
      lang={document.querySelector('html').getAttribute('lang')}
    />
  </Provider>,
  document.getElementById('presentation-container'),
);
