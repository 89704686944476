/* eslint-env browser */
const labels = {
  da: {
    tab1: 'Aarhus Universitet',
    tab2: 'Fakulteter og institutter',
    tab3: 'Centre',
    tab3_link: '//www.au.dk/forskning/forskningscentre/',
    tab4: 'Personer og bygninger',
    tab5: 'Oversigtskort',
    tab6: 'Information om Corona',
    tab6_link: 'https://medarbejdere.au.dk/corona',
    presentation: window.presentationText || '<p>Aarhus Universitet er et landsdækkende universitet med hovedsæde i Aarhus, campus i tre byer og forskningsaktiviteter 18 forskellige steder i Danmark, Grønland og på Tenerife.</p><p>Universitetet dækker et areal på ca. 580.000 kvadratmeter (netto). Det svarer til ca. 80 fodboldbaner.</p>',
    greenland: 'Grønland',
    searchPeople: 'Søg efter person',
    searchPeoplePH: 'Navn, e-mail eller tlf.',
    searchBuildings: 'Søg efter bygning',
    searchBuildingsPH: 'Bygningsnummer eller -navn',
    building: 'Bygning',
    askov: '<h3>Askov</h3><p>Forsøgsstation til gødningsforsøg under Institut for Agroøkologi.</p>',
    emdrup: '<h3>Emdrup (København)</h3><p>DPU – Danmarks Institut for Pædagogik og Uddannelse, ca. 320 medarbejdere og 2.100 studerende.</p>',
    flakkebjerg: '<h3>Flakkebjerg</h3><p>Forskning i plantebeskyttelse. Institut for Agroøkologi og Institut for Molekylærbiologi og Genetik, ca. 150 medarbejdere.</p>',
    foulum: '<h3>Viborg/Foulum</h3><p>DCA - Nationalt Center for Fødevarer og Jordbrug, Institut for Agroøkologi, Institut for Husdyrvidenskab, Institut for Fødevarer og Institut for Molekylærbiologi og Genetik. Forskning inden for fødevarer og jordbrug, ca. 600 medarbejdere.</p>',
    herning: '<h3>Herning</h3><p>Institut for Forretningsudvikling og Teknologi, ca. 100 medarbejdere og 1.100 studerende.</p>',
    horsens: '<h3>Horsens/Bygholm</h3><p>Engineering Centre Bygholm, Institut for Ingeniørvidenskab.</p>',
    jels: 'Tekst om Jels...',
    jyndevad: '<h3>Jyndevad</h3><p>Forsøgsstation med dyrkningsfaciliteter til planteforsøg under Institut for Agroøkologi.</p>',
    kalo: '<h3>Kalø/Rønde</h3><p>Forskningsfaciliteter til markforsøg under Institut for Bioscience, ca. 50 medarbejdere.</p>',
    roskilde: '<h3>Roskilde</h3><p>Institut for Bioscience, Institut for Miljøvidenskab, Institut for Geoscience og DCE - Nationalt Center for Miljø og Energi, ca. 250 medarbejdere.</p>',
    ronbjerg: '<h3>Rønbjerg</h3><p>Marinbiologisk Station. Forskningsfaciliteter, som drives af Sektion for Akvatisk Biologi, Institut for Bioscience.</p>',
    silkeborg: '<h3>Silkeborg</h3><p>Institut for Bioscience og DCE - Nationalt Center for Miljø og Energi, ca. 100 medarbejdere.</p>',
    aarhus: '<h3>Aarhus</h3><p>Hovedcampus for Aarhus Universitet og de fem fakulteter Arts, Aarhus BSS, Health, Natural Sciences og Technical Sciences.</p>',
    aarslev: '<h3>Årslev</h3><p>Institut for Fødevarer. Forskning i fødevarers kvalitet og sammensætning. Ca. 70 medarbejdere.</p>',
    nuuk: 'Tekst om Nuuk...',
    villum: '<h3>Villum Research Station</h3><p>Forsøgsstation i Nordgrønland. Atmosfærisk og marin forskning. Ejes af den grønlandske regering og drives af Institut for Miljøvidenskab i samarbejde med Forsvaret.</p>',
    zackenberg: '<h3>Zackenberg</h3><p>Forsøgsstation i Nordøstgrønland. Forskning og overvågning af økosystemer. Ejes af den grønlandske regering og drives af Institut for Bioscience.</p>',
    song: '<h3>Tenerife</h3><p>SONG-teleskopet. Forskningsfaciliteter under Institut for Fysik og Astronomi. Et samarbejde mellem Aarhus Universitet og Københavns Universitet.</p>',
    moesgaard: '<h3>Moesgaard</h3><p>Institut for Kultur og Samfund.</p>',
    grundtvig: '<h3>Grundtvig Centeret (København)</h3><p>Forskning og formidling i Grundtvigs værker og tanker. En del af Institut for Kultur og Samfund.</p>',
    askov_link: 'http://agro.au.dk/faciliteter/askov/',
    emdrup_link: 'http://edu.au.dk',
    flakkebjerg_link: 'http://dca.au.dk/om-dca/au-flakkebjerg/',
    foulum_link: 'http://dca.au.dk',
    herning_link: 'http://btech.au.dk',
    horsens_link: 'http://eng.au.dk/forskning/laboratorier-og-faciliteter/engineering-centre-bygholm/',
    jels_link: '',
    jyndevad_link: 'http://agro.au.dk',
    kalo_link: 'http://bios.au.dk/faciliteter/long-term-experimental-plot/',
    roskilde_link: 'http://bios.au.dk',
    ronbjerg_link: 'http://bios.au.dk/faciliteter/roenbjerg/',
    silkeborg_link: 'http://bios.au.dk',
    aarhus_link: 'http://www.au.dk/kort/',
    aarslev_link: 'http://food.au.dk',
    nuuk_link: '',
    villum_link: 'http://villumresearchstation.dk/',
    zackenberg_link: 'http://zackenberg.dk/',
    song_link: 'http://song.au.dk/',
    moesgaard_link: 'http://cas.au.dk',
    grundtvig_link: 'http://grundtvigcenteret.au.dk/',
    centersAlphabetical: 'Alfabetisk',
    centersFaculties: 'Fakultet',
    centersTypes: 'Centertype',
    centersPlaceHolder: 'Søg efter center',
    showOnMap: 'Vis på bygningskortet',
    factsHeadline: 'Fakta',
    factsLarge: window.factsLarge || 'Aarhus Universitet arbejder for at være et toneangivende, globalt orienteret universitet med højeste kvalitet i uddannelse og forskning og et stærkt engagement i samfundsudviklingen.',
    factsSmall: window.factsSmall || 'I kraft af sin størrelse og faglige tyngde som et forskningsintensivt universitet har Aarhus Universitet stor gennemslagskraft på tværs af hele fagspektret – lokalt, nationalt og globalt.',
    factsStudents: 'Studerende',
    factsStaff: 'Medarbejdere',
    factsBudget: 'Omsætning (mia. kr.)',
    factsStudentsNumber: window.factsStudentsNumber,
    factsStaffNumber: window.factsStaffNumber,
    factsBudgetNumber: window.factsBudgetNumber,
  },
  en: {
    tab1: 'Aarhus University',
    tab2: 'Faculties, schools, and departments',
    tab3: 'Centres',
    tab3_link: '//www.au.dk/en/research/research-centres/',
    tab4: 'Persons and buildings',
    tab5: 'Map',
    tab6: 'Information about Corona',
    tab6_link: 'https://medarbejdere.au.dk/en/corona',
    presentation: window.presentationText || '<p>Aarhus University has its main campus in Aarhus, campuses in three other locations in Denmark and research activities at 18 different locations in Denmark, Greenland and on Tenerife.</p><p>The university’s locations cover 580,000 net square metres, the equivalent of about 80 football pitches.</p>',
    greenland: 'Greenland',
    searchPeople: 'Search for person',
    searchPeoplePH: 'Name, email or phone number',
    searchBuildings: 'Search for building',
    searchBuildingsPH: 'Building number or name',
    building: 'Building',
    askov: '<h3>Askov</h3><p>Research station for field trials of fertilisers under the Department of Agroecology.</p>',
    emdrup: '<h3>Emdrup (Copenhagen)</h3><p>The Danish School of Education (DPU), approx. 320 employees and 2,100 students.</p>',
    flakkebjerg: '<h3>Flakkebjerg</h3><p>Application-oriented research on plants and crop production. Department of Agroecology and Department of Molecular Biology and Genetics, approx. 150 employees.</p>',
    foulum: '<h3>Viborg/Foulum</h3><p>DCA – Danish Centre for Food and Agriculture, Department of Agroecology, Department of Animal Science, Department of Food Science and Department of Molecular Biology and Genetics. Food and agriculture research, approx. 600 employees.</p>',
    herning: '<h3>Herning</h3><p>Department of Business Development and Technology, approx. 100 employees and 1,100 students.</p>',
    horsens: '<h3>Horsens/Bygholm</h3><p>Engineering Centre Bygholm, laboratory and test facilities, Department of Engineering.</p>',
    jels: 'Tekst om Jels...',
    jyndevad: '<h3>Jyndevad</h3><p>Research station for agricultural field trials under the Department of Agroecology.</p>',
    kalo: '<h3>Kalø/Rønde</h3><p>Experimental field plots, Department of Bioscience, approx. 50 employees.</p>',
    roskilde: '<h3>Roskilde</h3><p>DCE – Danish Centre for Environment and Energy, Department of Bioscience, Department of Environmental Science, Department of Geoscience and DCE - Danish Centre For Environment And Energy, approx. 250 employees.</p>',
    ronbjerg: '<h3>Rønbjerg</h3><p>Rønbjerg Marine Research Station. Research facilities run by the Section for Aquatic Biology at the Department of Bioscience.</p>',
    silkeborg: '<h3>Silkeborg</h3><p>Department of Bioscience and DCE - Danish Centre For Environment And Energy, approx. 100 employees.</p>',
    aarhus: '<h3>Aarhus</h3><p>The main campus of Aarhus University and the five faculties (Arts, Aarhus BSS, Health, Natural Sciences and Technical Sciences).</p>',
    aarslev: '<h3>Årslev</h3><p>Department of Food Science. Research on food quality and composition.  Approx. 70 employees.</p>',
    nuuk: 'Tekst om Nuuk...',
    villum: '<h3>Villum Research Station</h3><p>Research station in northern Greenland. Atmospheric and marine research. Owned by the government of Greenland and operated by the Department of Environmental Science in collaboration with Defence Command Denmark.</p>',
    zackenberg: '<h3>Zackenberg</h3><p>Research station in northeast Greenland. Research and monitoring of ecosystems. Owned by the government of Greenland and operated by the Department of Bioscience.</p>',
    song: '<h3>Tenerife</h3><p>The SONG telescope. Research facilities under the Department of Physics and Astronomy. Owned and operated by Aarhus University and the University of Copenhagen.</p>',
    moesgaard: '<h3>Moesgaard</h3><p>Department of Culture and Society.</p>',
    grundtvig: '<h3>The Grundtvig Study Centre (Copenhagen)</h3><p> Research and resource centre on the work and thought of N.F.S. Grundtvig. Under the School of Culture and Society.</p>',
    askov_link: 'http://agro.au.dk/en/field-trials-and-semi-field-areas/au-askov/',
    emdrup_link: 'http://edu.au.dk/en/',
    flakkebjerg_link: 'http://dca.au.dk/en/about-dca/au-flakkebjerg/',
    foulum_link: 'http://dca.au.dk/en/',
    herning_link: 'http://btech.au.dk/en/',
    horsens_link: 'http://eng.au.dk/en/research-in-engineering/laboratory-facilities/engineering-centre-bygholm/',
    jels_link: '',
    jyndevad_link: 'http://agro.au.dk/en/',
    kalo_link: 'http://bios.au.dk/en/faciliteter-en/kaloe-experimental-plot/',
    roskilde_link: 'http://bios.au.dk/en/',
    ronbjerg_link: 'http://bios.au.dk/en/faciliteter-en/roenbjerg-en/',
    silkeborg_link: 'http://bios.au.dk/en/',
    aarhus_link: 'http://www.au.dk/en/map/',
    aarslev_link: 'http://food.au.dk/en/',
    nuuk_link: '',
    villum_link: 'http://villumresearchstation.dk/',
    zackenberg_link: 'http://zackenberg.dk/',
    song_link: 'http://song.au.dk/',
    moesgaard_link: 'http://cas.au.dk/en/',
    grundtvig_link: 'http://grundtvigcenteret.au.dk/',
    centersAlphabetical: 'Alphabetical',
    centersFaculties: 'Faculty',
    centersTypes: 'Centre type',
    centersPlaceHolder: 'Search for centre',
    showOnMap: 'Show on building map',
    factsHeadline: 'Facts',
    factsLarge: window.factsLarge || 'Aarhus University’s ambition is to be a globally-oriented university with a commitment to excellence in research and education and a strong engagement in the development of society.',
    factsSmall: window.factsSmall || 'Owing to its size and impressive results as a research-intensive university, Aarhus University has a strong reputation and influence across the entire spectrum of disciplines, locally, nationally and globally.',
    factsStudents: 'Students',
    factsStaff: 'Staff',
    factsBudget: 'Budget (EUR million)',
    factsStudentsNumber: window.factsStudentsNumber,
    factsStaffNumber: window.factsStaffNumber,
    factsBudgetNumber: window.factsBudgetNumber,
  },
};

export default labels;
